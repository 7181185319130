body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(45deg, #f06, #00c6ff, #f06); */
  
  backdrop-filter: blur(5px); 
  background-image: url('cool-background.png'); 
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

/* Add custom styles to change the overall theme color */
body {

}

/* Navigation Bar Styles */
nav {
  display: flex;
  justify-content: space-between;
  font-family: 'sans-serif';
}

nav ul {
  list-style: none;
  padding: 0;
  display: flex;
}

nav li {
  margin: 10px;
}

/* Neon Button Styles for Navigation Bar */
nav a {
  text-decoration: none;
  color: rgb(55, 51, 54);
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  /* background-image: linear-gradient(45deg,white, #c9a0dc); */
  background-color: rgba(255, 255, 255, 1); /*White color with 70% opacity*/
  
  background-size: 200% auto;
  background-position: 0 50%;
  font-size: 24px;
  font-weight: bold;
  font-family: 'sans-serif';

}

nav a::before {
  content: '';
  position: relative;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  /* background-image: linear-gradient(45deg, white, #c9a0dc); */
  z-index: -1;
  border-radius: 8px;
  opacity: 0;
  transition: opacity 0.2s;
}

nav a:hover::before {
  opacity: 1;
}

nav a:hover {
  /*animation: glowing 1.5s ease infinite;*/
  animation: shining 1s ease infinite;
  color: #f06;
  box-shadow: 0 0 15px #00c6ff;
}

@keyframes shining {
  0% {
    background-position: 0 50%;
  }
  100% {
    background-position: 400% 50%;
  }
}

@keyframes glowing {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 400% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.container-navbar {
  /* background-color: linear-gradient(45deg, rgba(255, 255, 255, 1.0), rgba(255, 255, 255, 0.8)); White color with 70% opacity */
  background: linear-gradient(45deg, white, white, rgba(255, 255, 255, 0.7));

  padding: 20px;
  margin: 20px;
  border-radius: 12px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  height: 75px;
  
}

/* Center the main content */
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

.row-container {
  display: flex;
  justify-content: space-between; /* Align items with space between */
}


.main-content {
  text-align: center;
  padding: 20px 0;
  position: relative; /* Add this for positioning the pseudo-element */
}

.important-content {
  text-align: center;
  padding: 20px 20px;
  position: absolute; /* Add this for positioning the pseudo-element */
  background-color: white;
  height: calc(100vh);
  width: calc(100vh);
  background-image: None;

}

/* Blurred Light Region behind Main Text */
.main-content::before {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2));
  filter: blur(10px); /* Apply blur effect */
  z-index: -1;
  border-radius: 10px;
}



/* Styles for the Subscribe Form */
.subscribe-form {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  margin: 20px;
  border-radius: 12px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
}

.subscribe-form input[type="email"] {
  padding: 10px;
  border-radius: 10px;
  border: 2px solid darkgray;
  margin: 10px;
  font-size: 18px;
  width: 250px;
}
.subscribe-form input[type="email"]:focus {
  border: 2px solid #f06; /* Change the background color on focus */
}

.subscribe-form button {
  text-decoration: none;
  color: #ffffff;
  padding: 10px 16px;
  margin: 10px;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(45deg, #f06, #00c6ff, #f06);
  background-size: 200% auto;
  background-position: 0 50%;
  font-size: 18px;
  font-weight: bold;
}

.subscribe-form button::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background-image: linear-gradient(45deg, #f06, #00c6ff, #f06);
  z-index: -1;
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.2s;
}

.subscribe-form button:hover::before {
  opacity: 1;
}

.subscribe-form button:hover {
  animation: glowing 1.5s ease infinite;
  box-shadow: 0 0 10px white;
}


.logo {
  width: 40px; /* Adjust the logo size as needed */
  height: 40px;
  margin-right: 10px;
}

.container-endpage {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
}


